<template>
  <div class="order_details" :style="order.dealStatus == 2 ? 'bottom:0' : ''">
    <div class="state">
      <div
        class="state_content"
        :style="order.dealStatus == 0 ? '' : ' justify-content: center;'"
      >
        <strong v-if="order.dealStatus == 0">等待付款</strong>
        <strong v-else-if="order.dealStatus == 2">待发货</strong>
        <strong v-else-if="order.dealStatus == 3">待自提</strong>
        <strong v-else-if="order.dealStatus == 4">已收货</strong>
        <strong v-else-if="order.dealStatus == 8">待收货</strong>
        <strong v-else-if="order.dealStatus == 7">交易关闭</strong>
        <!-- <strong v-else-if="order.dealStatus == 8">已付款</strong> -->
        <strong v-else-if="order.dealStatus == 9">已退款</strong>
        <p v-if="order.dealStatus == 0">支付剩余时间 {{ timeout }}</p>
        <span v-if="order.dealStatus == 0"
          >请您尽快完成付款，超时订单将自动取消</span
        >
      </div>
      <img
        v-if="order.dealStatus == 0"
        src="@/assets/images/obligation_icon.png"
        alt=""
      />
      <img
        v-if="order.dealStatus == 7"
        src="@/assets/images/close_icon.png"
        alt=""
      />
      <img
        v-if="
          order.dealStatus == 6 ||
          order.dealStatus == 2 ||
          order.dealStatus == 3 ||
          order.dealStatus == 8
        "
        src="@/assets/images/daishouhuo.png"
        alt=""
      />
      <img
        v-if="order.dealStatus == 4"
        src="@/assets/images/succeed_icon.png"
        alt=""
      />
    </div>
    <div
      class="take_their_tips"
      v-if="
        !order.freeShipping &&
        order.dealStatus != 4 &&
        order.dealStatus != 7 &&
        order.dealStatus != 9
      "
    >
      <p>订单包含自提商品，需要您到店自提，请确认提货信息</p>
      <p>预计最晚{{ takeTheirTime }}可自提</p>
      <p>（注：下单后2个工作日内配送，配送当天提前1个小时电话联系用户）</p>
    </div>
    <div class="order_content">
      <div class="user_info" v-if="order.freeShipping">
        <svg-icon icon-class="address_icon" />
        <div>
          <strong>{{ order.consignee }} {{ order.telephone }}</strong>
          <p>
            {{ order.province + order.city + order.county + order.address }}
          </p>
        </div>
      </div>
      <div class="user_info" @click="takeTheirShow = true" v-else>
        <svg-icon icon-class="address_icon" />
        <div>
          <strong>自提点地址：{{ order.getGoodsAddress }}</strong>
          <!-- <p>自提点电话：{{ order.telNum }}</p> -->
        </div>
        <svg-icon icon-class="home_more_icon" class="address_more" />
      </div>

      <div class="customer_service">
        <div class="left">
          <b>请添加正品汇客服为好友，咨询订单相关售后服务等内容。</b>
          <span>长按图片识别添加好友</span>
        </div>
        <img src="@/assets/images/customer_service2.png" width="60px" alt="" />
      </div>

      <div class="goods_info">
        <div class="store" v-if="!order.integralPay">
          <svg-icon icon-class="store_icon" class="svg_store" />
          <b @click="goStore(order.storeId)">{{ order.storeName }}</b>
          <svg-icon
            icon-class="home_more_icon"
            class="svg_more"
            @click="goStore(order.storeId)"
          />
        </div>
        <div
          class="goods"
          v-if="order.integralPay"
          @click="
            $router.push({
              name: 'integral-goods',
              query: {
                integralGoodsId: order.commId,
              },
            })
          "
        >
          <div class="top">
            <img alt="" v-lazy="order.filePath" />
            <div class="details">
              <div class="details_top">
                <h4>
                  {{ order.commodityName }}
                </h4>
                <!-- <p>
              归属品牌：<span>{{ list.brandName }}</span>
            </p> -->
                <p>
                  商品规格：<span>{{ order.specsParameter }}</span>
                </p>
                <span v-if="order.attributeName" class="attribute">{{
                  order.attributeName
                }}</span>
              </div>
              <div class="price">
                <span>×{{ order.totalQuantity }}</span>
                <b style="color: #fb4e10; font-size: 0.16rem">
                  {{ order.bankIntegral / order.totalQuantity }}积分
                </b>
              </div>
            </div>
          </div>
        </div>
        <div
          class="goods"
          v-for="(list, index) in order.orderCommodityListItems"
          :key="index"
          v-else
          @click="
            $router.push({
              path: '/goodsDetails',
              query: {
                barcode: list.barcode,
                enterpriseId: list.enterpriseId,
              },
            })
          "
        >
          <div class="top">
            <img alt="" v-lazy="list.filePath" />
            <div class="details">
              <div class="details_top">
                <h4>
                  <span v-if="list.type === 1">[新人尝鲜价] </span
                  >{{ list.commodityName }}
                </h4>
                <!-- <p>
              归属品牌：<span>{{ list.brandName }}</span>
            </p> -->
                <p>
                  商品规格：<span>{{ list.specsParameter }}</span>
                </p>
                <span v-if="list.attributeName" class="attribute">{{
                  list.attributeName
                }}</span>
              </div>
              <div class="price">
                <span>×{{ list.quantity }}</span>
                <div>
                  <span
                    >单价:<span>￥</span
                    >{{ (list.sellingPrice / list.quantity).toFixed(2) }}</span
                  >
                  <span style="margin-left: 8px"
                    >实付:<span>￥</span
                    >{{ list.actualPayment.toFixed(2) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="bottom">
            <van-button round v-if="list.examineState && !list.freeze"
              >加入购物车</van-button
            >
            <van-button round @click="refundShow = true">申请退款</van-button>
          </div> -->
        </div>
        <!-- <ul
          class="list van-hairline--top"
          style="padding-top: 12px; margin-top: 12px"
        >
          <li>
            <span>所属公司</span>
            <p>{{ order.senterpriseName }}</p>
          </li>
          <li>
            <span>收款账号</span>
            <p><a>点击查看</a></p>
          </li>
          <a :href="`tel:${order.storeServicePhone}`" class="tel"
            >客服电话<svg-icon icon-class="phone_icon"
          /></a>
        </ul> -->
      </div>
      <div class="order_info">
        <ul class="list" ref="orderInfo">
          <li>
            <span>订单编号</span>
            <p>{{ order.dealNumber }}</p>
          </li>
          <li>
            <span>下单时间</span>
            <p>{{ order.createTime2 }}</p>
          </li>
          <li>
            <span>支付方式</span>
            <p>
              {{
                order.integralPay
                  ? "积分支付"
                  : order.payStatus
                  ? "微信支付"
                  : "未支付"
              }}
            </p>
          </li>
          <li>
            <span>配送方式</span>
            <p>{{ order.freeShipping ? "包邮" : "自提" }}</p>
            <!-- <p>{{ order.shipper }}</p> -->
          </li>
          <li>
            <span>商品金额</span>
            <!-- <p><i>¥</i>{{ order.totalAmount }}</p> -->
            <p v-if="order.integralPay">{{ order.bankIntegral }}积分</p>
            <p v-else><i>¥</i>{{ totalAmount.toFixed(2) }}</p>
          </li>
          <li>
            <span>运费</span>
            <p>0.00</p>
          </li>
          <li v-if="!order.integralPay">
            <span>积分抵扣</span>
            <p
              class="credit_points_icon"
              @click="bankIntegral || integral ? (integralShow = true) : ''"
            >
              抵扣{{ (integral + bankIntegral).toFixed(2) }}元
              <svg-icon
                icon-class="home_more_icon"
                v-if="bankIntegral || integral"
              />
            </p>
          </li>
          <li>
            <span>买家留言</span>
            <p>{{ order.remarks ? order.remarks : "无" }}</p>
          </li>
        </ul>
        <p class="open_all">
          <span @click="openAll"
            >{{ more ? "收起全部" : "查看全部"
            }}<svg-icon
              icon-class="home_more_icon"
              :style="
                more
                  ? 'transform: rotate(-90deg);'
                  : 'transform: rotate(90deg);'
              "
          /></span>
        </p>
        <strong class="actual_payment" v-if="order.integralPay"
          >积分支付：<span>{{ order.bankIntegral }}积分</span></strong
        >
        <strong class="actual_payment" v-else-if="order.dealStatus == 0"
          >需付款：<span
            ><i>￥</i
            >{{ (totalAmount - integral - bankIntegral).toFixed(2) }}</span
          ></strong
        >
        <strong class="actual_payment" v-else-if="order.payStatus == 1"
          >已付款：<span
            ><i>￥</i
            >{{ (totalAmount - integral - bankIntegral).toFixed(2) }}</span
          ></strong
        >
      </div>
    </div>
    <div
      class="operating_button van-hairline--top"
      v-if="order.dealStatus !== 2"
    >
      <van-button
        round
        v-if="order.payStatus"
        :url="`#/singlePoster?dealNumber=${$route.query.dealNumber}`"
        color="#009A4D"
        plain
        >去晒单</van-button
      >
      <van-button round v-if="order.dealStatus == 0" @click="cancellationOrder"
        >取消订单</van-button
      >

      <van-button
        round
        color="#009A4D"
        @click="goLogistics(order)"
        v-if="order.logisticsInfo"
        >查看物流</van-button
      >
      <van-button
        round
        v-if="
          order.dealStatus == 7 ||
          order.dealStatus == 5 ||
          order.dealStatus == 9
        "
        @click="deleteOrder"
        >删除订单</van-button
      >
      <!-- <van-button round v-if="order.dealStatus == 0">修改地址</van-button> -->
      <van-button
        round
        color="#009A4D"
        class="pay"
        v-if="order.dealStatus == 0"
        @click="goPay"
        >付款</van-button
      >
      <!-- <van-button round v-if="order.dealStatus == 4 || order.dealStatus == 5"
        >整单退款</van-button
      > -->
      <!-- <van-button round @click="refundShow = true">整单退款</van-button> -->
      <van-button
        round
        v-if="order.dealStatus == 3 || order.dealStatus == 8"
        @click="confirmOrder"
        >确认收货</van-button
      >
      <!-- <van-button round >查看保单</van-button> -->
    </div>
    <div v-if="error" class="error">
      <van-empty image="error" description="订单不存在" />
    </div>
    <van-overlay
      :show="integralShow"
      @click="integralShow = false"
      z-index="1000"
      class="integral_show"
    >
      <div class="wrapper" @click.stop>
        <div class="title">
          积分抵扣明细<svg-icon
            icon-class="close"
            @click="integralShow = false"
          />
        </div>
        <div class="content">
          <div v-if="bankIntegral">
            <p>平坝银行VIP</p>
            <span>{{
              `（使用${Math.round(
                bankIntegral * 100
              )}积分抵扣${bankIntegral.toFixed(2)}元）`
            }}</span>
          </div>
          <div v-if="integral">
            <p>正品汇平台积分</p>
            <span>{{
              `（使用${Math.round(integral * 100)}积分抵扣${integral.toFixed(
                2
              )}元）`
            }}</span>
          </div>
        </div>
      </div>
    </van-overlay>

    <van-popup
      v-model="takeTheirShow"
      position="bottom"
      class="takeTheir_popup"
    >
      <div class="title">
        提示<svg-icon icon-class="close" @click="takeTheirShow = false" />
      </div>
      <div class="content">
        <div class="tips">
          <p>订单包含自提商品，需要您到店自提，请确认提货信息</p>
          <p>预计最晚{{ takeTheirTime }}可自提</p>
          <p>（注：下单后2个工作日内配送，配送当天提前1个小时电话联系用户）</p>
        </div>
        <div class="info">
          <h3>
            提货人：{{ order.consignee }}&nbsp;&nbsp;{{ order.telephone }}
          </h3>
          <h3>自提点：{{ order.getGoodsName }}</h3>
          <div>
            <span>店长：</span>
            <p>{{ order.name }}</p>
          </div>
          <!-- <div>
            <span>电话：</span>
            <p>{{ order.telNum }}</p>
          </div> -->
          <div>
            <span>自提地址：</span>
            <p>
              {{ order.getGoodsAddress }}
            </p>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="refundShow"
      closeable
      position="bottom"
      :style="{ height: '40%' }"
      class="refund"
    >
      <div class="content">
        <img src="@/assets/images/th_icon2.png" alt="" />
        <p>商品未发货，享极速到账</p>
      </div>
      <van-button round type="primary" color="#009A4D" @click="confirmRefund"
        >确认退款 ￥168.00</van-button
      >
    </van-popup>

    <van-popup
      v-model="refundDetailsShow"
      closeable
      position="bottom"
      class="refund_details"
    >
      <div class="content">
        <div class="content_top">
          <img src="@/assets/images/tkcg_icon.png" alt="" />
          <p>退款成功</p>
        </div>
        <div class="info">
          <h5>退款信息</h5>
          <div class="store">
            <svg-icon icon-class="store_icon" class="svg_store" />
            <b>{{ order.storeName }}</b>
            <!-- <svg-icon
              icon-class="home_more_icon"
              class="svg_more"
              @click="goStore(order.storeId)"
            /> -->
          </div>
          <div
            class="goods"
            v-for="(list, index) in order.orderCommodityListItems"
            :key="index"
          >
            <div class="top">
              <img alt="" v-lazy="list.filePath" />
              <div class="details">
                <h4>{{ list.commodityName }}</h4>
                <p>
                  商品规格：<span>{{ list.specsParameter }}</span>
                </p>
                <span v-if="list.attributeName" class="attribute">{{
                  list.attributeName
                }}</span>
                <div class="price">
                  <span>×{{ list.quantity }}</span>
                  <div>
                    <span
                      >单价:<span>￥</span
                      >{{
                        (list.sellingPrice / list.quantity).toFixed(2)
                      }}</span
                    >
                    <span style="margin-left: 8px"
                      >实付:<span>￥</span
                      >{{ list.actualPayment.toFixed(2) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="list">
            <li>
              <span>退款类型</span>
              <p>仅退款（无需退货）</p>
            </li>
            <li>
              <span>退款金额</span>
              <p>¥168.00</p>
            </li>
            <li>
              <span>退款返还积分</span>
              <p>300</p>
            </li>
            <li>
              <span>退款路径</span>
              <p>原路返回</p>
            </li>
            <li>
              <span>货物状态</span>
              <p>未收到货</p>
            </li>
            <li>
              <span>退款原因</span>
              <p>不喜欢/不想要</p>
            </li>
            <li>
              <span>退款数量</span>
              <p>1</p>
            </li>
            <li>
              <span>补充说明</span>
              <p><a>点击查看</a></p>
            </li>
            <li>
              <span>退款时间</span>
              <p>2022-02-10 10:01:28</p>
            </li>
            <li>
              <span>退款编号</span>
              <p>T202212656562562334556</p>
            </li>
            <li>
              <span>订单编号</span>
              <p>DD202211190002100271</p>
            </li>
            <li>
              <span>售后操作记录</span>
              <p><a>点击查看</a></p>
            </li>
          </ul>
          <p>
            <span
              >{{ refundMore ? "收起全部" : "查看全部"
              }}<svg-icon
                icon-class="home_more_icon"
                :style="
                  refundMore
                    ? 'transform: rotate(-90deg);'
                    : 'transform: rotate(90deg);'
                "
            /></span>
          </p>
        </div>
      </div>
      <van-button round type="primary" color="#009A4D">联系店长</van-button>
    </van-popup>
  </div>
</template>

<script>
import { countDown, formatDate, twoDaysLater } from "@/utils/validate";
import { Dialog } from "vant";
import { getOpenId, removeOpenId } from "@/utils/auth";
import { mapState } from "vuex";
export default {
  name: "order-details",
  data() {
    return {
      order: {
        freeShipping: true,
      },
      timeout: 0,
      totalAmount: 0,
      integral: 0,
      bankIntegral: 0,
      error: false,
      more: true,

      takeTheirShow: false,
      integralShow: false,
      refundShow: false,
      refundDetailsShow: false,
      refundMore: true,
      takeTheirTime: "--月--日（周--）",
    };
  },
  computed: {
    ...mapState("user", ["userInfo", "allIntegral", "bank", "tzInfo"]),
  },
  created() {
    this.getDealDetails();
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.t);
  },
  methods: {
    async getDealDetails() {
      let data;
      if (this.$route.query.dealNumber) {
        data = await this.$API.order.DealDetails({
          object: {
            dealNumber: this.$route.query.dealNumber,
          },
        });
      } else if (this.$route.query.integralDealNumber) {
        data = await this.$API.order.IntegralDealDetails({
          object: {
            dealNumber: this.$route.query.integralDealNumber,
          },
        });
      }

      this.order = data.data.data;
      this.order.logisticsInfo = false;
      if (!this.order.dealNumber) {
        this.error = true;
        return;
      }

      this.takeTheirTime = twoDaysLater(this.order.createTime);
      this.order.createTime2 = formatDate(this.order.createTime);
      this.order.telephone2 =
        this.order.telephone.substr(0, 3) +
        "****" +
        this.order.telephone.substr(7);
      if (this.order.orderCommodityListItems) {
        this.order.orderCommodityListItems.forEach((goods) => {
          if (goods.attributeName)
            goods.attributeName = goods.attributeName.split(",").join("/");
          goods.filePath = goods.filePath.split(",")[0].endsWith(".mp4")
            ? goods.filePath.split(",")[1]
            : goods.filePath.split(",")[0];
          this.totalAmount += goods.sellingPrice;
          this.integral += goods.integral;
          this.bankIntegral += goods.bankIntegral;
          if (goods.waybillNumber) this.order.logisticsInfo = true;
        });
      } else {
        this.order.attributeName = this.order.attributeName
          .split(",")
          .join("/");
        this.order.filePath = this.order.filePath.split(",")[0].endsWith(".mp4")
          ? this.order.filePath.split(",")[1]
          : this.order.filePath.split(",")[0];
        this.order.integralPay = true;
      }

      this.order.dealStatus == 0 && this.setCountDown();
    },
    setCountDown() {
      this.timeout = countDown(this.order.createTime);
      this.t = setInterval(() => {
        this.timeout = countDown(this.order.createTime);
      }, 1000);
    },
    goPay() {
      if (getOpenId() == "undefined" || getOpenId() == "null") removeOpenId();
      // console.log(this.order);
      this.$router.push({
        path: "/confirmAnOrder/payForTheOrder",
        query: {
          // totalDealNumber: this.$route.query.totalDealNumber,
          dealNumber: this.$route.query.dealNumber,
        },
      });
    },
    cancellationOrder() {
      Dialog.confirm({
        title: "取消订单",
        message: "确定取消订单？",
      })
        .then(async () => {
          const data = await this.$API.order.CancelOrder({
            object: {
              // totalDealNumber: this.$route.query.totalDealNumber,
              dealNumber: this.$route.query.dealNumber,
            },
          });

          if (this.integral || this.bankIntegral)
            await this.$store.dispatch("user/getCusBankFilePathList");
          if (this.integral) {
            await this.$API.user.AdjustmentIntegral({
              object: {
                availablePoints: this.bank.find((item) => {
                  return item.bankId === "0";
                }).integral,
                bankId: "0",
                integral: Math.round(this.integral * 100),
                operatorName: this.userInfo.userEntity.actualName
                  ? this.userInfo.userEntity.actualName
                  : "",
                operatorUserId: this.userInfo.id,
                remarks: this.$route.query.dealNumber,
                type: 3,
                userId: this.userInfo.id,
              },
            });
          }
          if (this.bankIntegral) {
            await this.$API.user.AdjustmentIntegral({
              object: {
                availablePoints: this.bank.find((item) => {
                  return item.bankId !== "0";
                }).integral,
                bankId: this.bank.find((item) => {
                  return item.bankId !== "0";
                }).bankId,
                integral: Math.round(this.bankIntegral * 100),
                operatorName: this.userInfo.userEntity.actualName
                  ? this.userInfo.userEntity.actualName
                  : "",
                operatorUserId: this.userInfo.id,
                remarks: this.$route.query.dealNumber,
                type: 3,
                userId: this.userInfo.id,
              },
            });
          }
          if (this.integral || this.bankIntegral)
            await this.$store.dispatch("user/getCusBankFilePathList");

          this.getDealDetails();
        })
        .catch(() => {});
    },
    deleteOrder() {
      Dialog.confirm({
        title: "删除订单",
        message: "确定删除订单？",
      })
        .then(async () => {
          const data = await this.$API.order.DeleteOrder({
            object: {
              // totalDealNumber: this.$route.query.totalDealNumber,
              dealNumber: this.$route.query.dealNumber,
            },
          });
          this.$router.back();
        })
        .catch(() => {});
    },
    confirmOrder() {
      Dialog.confirm({
        title: "确认收到货了吗?",
        message: "为了保证您的售后权益，请收到商品检查无误后再确认收货",
      })
        .then(async () => {
          const data = await this.$API.order.UpdateDealStatus({
            object: {
              dealNumber: this.$route.query.dealNumber,
              dealStatus: 4,
            },
          });
          this.getDealDetails();
        })
        .catch(() => {});
    },
    goStore(id) {
      this.$router.push({
        path: "/store",
        query: {
          storeId: id,
        },
      });
    },
    goLogistics(order) {
      let code = [];
      order.orderCommodityListItems.forEach((goods) => {
        if (goods.waybillNumber)
          code.push({
            filePath: goods.filePath,
            waybillNumber: goods.waybillNumber,
            shipperCode: goods.shipperCode,
            shipper: goods.shipper,
          });
      });
      if (code.length) {
        sessionStorage.setItem("waybillInfo", JSON.stringify(code));
        this.$router.push({
          path: "logisticsInformation",
        });
      } else {
        this.Toast.fail("暂无物流信息");
      }
    },
    openAll() {
      if (this.more) {
        this.$refs.orderInfo.style.height = `${
          this.$refs.orderInfo.children[0].offsetHeight +
          this.$refs.orderInfo.children[1].offsetHeight
        }px`;
      } else {
        this.$refs.orderInfo.style.height =
          this.$refs.orderInfo.scrollHeight + "px";
      }
      this.more = !this.more;
    },

    confirmRefund() {
      this.refundShow = false;
      this.refundDetailsShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin box {
  background: #fff;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 12px;
}
@mixin goods {
  .goods {
    margin-top: 12px;
    .top {
      display: flex;
      img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        margin-right: 12px;
      }
      .details {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .details_top {
          h4 {
            line-height: 22px;
            color: #333333;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            span {
              color: #fb4e10;
              font-weight: normal;
            }
          }
          p {
            line-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            color: #999999;
            span {
              color: #333333;
            }
          }
          .attribute {
            margin: 2px 0;
            display: inline-block;
            background: #f1f1f1;
            color: #878787;
            padding: 0 10px;
            line-height: 20px;
            border-radius: 10px;
          }
        }
        .price {
          // position: absolute;
          // bottom: 0;
          // width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            color: #999;
          }
          span {
            font-size: 13px;
            span {
              font-size: 12px;
              font-weight: normal;
              display: inline-block;
              transform: scale(0.8);
              -webkit-transform: scale(0.8);
              -moz-transform: scaleY(0.8);
              position: relative;
              left: 1px;
              top: 1px;
            }
          }
        }
      }
    }
    .bottom {
      margin-top: 16px;
      text-align: end;
      .van-button {
        margin-left: 12px;
        height: 38px;
        padding: 0 12px;
      }
    }
  }
}
@mixin store {
  .store {
    display: flex;
    align-items: center;
    b {
      color: #333333;
    }
    .svg_store {
      font-size: 12px;
      margin-right: 6px;
    }
    .svg_more {
      color: #999999;
      font-size: 10px;
      margin-left: 2px;
    }
  }
}
.order_details {
  position: absolute;
  top: 46px;
  bottom: 50px;
  left: 0;
  right: 0;
  //   max-width: 380px;
  // margin: 0 auto;
  background: #f5f5f5;
  overflow-y: scroll;
  .state {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: -moz-linear-gradient(top, #19e57f 0%, #009a4d 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, #19e57f),
      color-stop(100%, #009a4d)
    );
    background: -webkit-linear-gradient(top, #19e57f 0%, #009a4d 100%);
    background: -o-linear-gradient(top, #19e57f 0%, #009a4d 100%);
    background: -ms-linear-gradient(top, #19e57f 0%, #009a4d 100%);
    > img {
      width: 65px;
    }
    .state_content {
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 65px;
      strong {
        font-size: 18px;
        line-height: 18px;
      }
      p {
        line-height: 14px;
      }
      span {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
  .take_their_tips {
    background: #fff;
    padding: 10px 16px;
    text-align: center;
    p:nth-child(1) {
      font-weight: bold;
      color: #333333;
    }
    p:nth-child(2) {
      font-weight: bold;
      color: #fb4e10;
      margin: 4px 0;
    }
    p:nth-child(3) {
      font-size: 12px;
      color: #999999;
    }
  }
  .order_content {
    padding: 12px 15px;
    .user_info {
      @include box;
      display: flex;
      position: relative;
      .svg-icon {
        font-size: 22px;
        margin-right: 12px;
        flex-shrink: 0;
      }
      div {
        strong {
          font-size: 16px;
        }
        p {
          margin-top: 6px;
        }
      }
      .address_more {
        position: absolute;
        right: 4px;
        top: 50%;
        margin-right: 4px;
        font-size: 10px;
        color: #999;
        transform: translateY(-50%);
      }
    }
    .customer_service {
      @include box;
      display: flex;
      font-size: 12px;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span{
          color:#009a4d
        }
      }
      img {
        margin-left: 34px;
      }
    }
    .goods_info {
      @include box;
      @include store;
      @include goods;
    }
    .order_info {
      @include box;
      margin-bottom: 0;
      .actual_payment {
        display: block;
        text-align: end;
        font-size: 14px;
        color: #333;
        span {
          color: #fb4e10;
          font-size: 16px;
          i {
            font-size: 12px;
            font-style: normal;
          }
        }
      }
      .credit_points_icon {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .svg-icon {
          margin-left: 4px;
          font-size: 8px;
          color: #999999;
        }
      }
    }
    .list {
      text-align: center;
      overflow: hidden;
      transition: 0.3s all;
      li {
        display: flex;
        padding-bottom: 12px;
        line-height: 20px;
        span {
          // width: 56px;
          color: #666666;
          margin-right: 20px;
        }
        p {
          flex: 1;
          text-align: end;
          a {
            color: #1370fc;
            text-decoration: underline;
          }
          i {
            font-size: 12px;
            font-style: normal;
          }
        }
      }
      .tel {
        padding: 4px 14px;
        border: 1px solid #009a4d;
        display: inline-block;
        line-height: 20px;
        color: #009a4d;
        border-radius: 15px;
        .svg-icon {
          margin-left: 8px;
        }
      }
    }
    .open_all {
      color: #666;
      margin-bottom: 12px;
      text-align: center;
      .svg-icon {
        vertical-align: baseline;
        margin-left: 6px;
        transition: 0.3s all;
        font-size: 10px;
      }
    }
  }
  .operating_button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 500px;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    background: #fff;
    .van-button {
      height: 38px;
      padding: 0 12px;
      margin-left: 12px;
    }
    .pay {
      padding: 0 24px;
    }
  }
  .error {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: #f5f5f5;
    .van-empty {
      height: 100%;
    }
  }
  .takeTheir_popup {
    border-radius: 5px 5px 0px 0px;
    padding: 0 15px;
    max-height: 85%;
    .title {
      text-align: center;
      margin: 20px 0;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      position: relative;
      .svg-icon {
        position: absolute;
        right: 0;
        top: 3px;
        font-size: 14px;
        color: #878484;
      }
    }
    .content {
      .tips {
        text-align: center;
        p:nth-child(1) {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        p:nth-child(2) {
          font-size: 16px;
          font-weight: bold;
          color: #fb4e10;
          margin: 4px 0;
        }
        p:nth-child(3) {
          font-size: 12px;
          color: #999999;
        }
      }
      .info {
        margin: 32px 0;
        background: #f5f5f5;
        padding: 20px 12px;
        border-radius: 2px;
        h3 {
          font-size: 18px;
          color: #333333;
        }
        h3:nth-child(2) {
          margin: 28px 0 10px 0;
        }
        div {
          display: flex;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          span {
            flex-shrink: 0;
          }
        }
      }
    }
  }
  .integral_show {
    background: rgba(51, 51, 51, 0.39);
    display: flex;
    align-items: center;
    justify-content: center;
    .wrapper {
      background: #fff;
      border-radius: 5px;
      text-align: center;
      .title {
        margin: 18px 0;
        font-size: 17px;
        line-height: 24px;
        color: #333333;
        position: relative;
        .svg-icon {
          position: absolute;
          right: 15px;
          font-size: 12px;
          color: #878484;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .content {
        padding: 0 36px 36px;
        div {
          margin-bottom: 16px;
          font-size: 16px;
          span {
            color: #f98f10;
          }
        }
        div:last-child {
          margin: 0;
        }
      }
    }
  }
  .refund {
    border-radius: 5px 5px 0px 0px;
    text-align: center;
    overflow: hidden;
    .content {
      overflow-y: scroll;
      padding-bottom: 20px;
      position: absolute;
      top: 58px;
      bottom: 48px;
      width: 100%;
      img {
        width: 70px;
      }
      p {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        margin-top: 20px;
      }
    }
    .van-button {
      position: absolute;
      bottom: 4px;
      left: 15px;
      right: 15px;
    }
  }
  .refund_details {
    border-radius: 5px 5px 0px 0px;
    height: 80vh;
    .content {
      position: absolute;
      top: 52px;
      bottom: 52px;
      overflow-y: scroll;
      left: 0;
      right: 0;
      padding: 0 15px;
      .content_top {
        text-align: center;
        img {
          width: 50px;
        }
        p {
          line-height: 18px;
          font-size: 18px;
          font-weight: bold;
          color: #fb4e10;
          margin: 14px 0 24px 0;
        }
      }
      .info {
        h5 {
          font-size: 16px;
          margin-bottom: 16px;
        }
        @include store;
        @include goods;
        .list {
          margin-top: 20px;
          li {
            display: flex;
            justify-content: space-between;
            padding-bottom: 4px;
            span {
              // width: 56px;
              color: #666666;
              margin-right: 20px;
            }
            p {
              flex: 1;
              text-align: end;
              a {
                color: #1370fc;
                text-decoration: underline;
              }
              i {
                font-size: 12px;
                font-style: normal;
              }
            }
          }
        }
        > p {
          color: #666;
          margin: 12px 0;
          text-align: center;
          .svg-icon {
            vertical-align: baseline;
            margin-left: 6px;
            transition: 0.3s all;
            font-size: 10px;
          }
        }
      }
    }
    .van-button {
      position: absolute;
      bottom: 4px;
      left: 15px;
      right: 15px;
    }
  }
}

// @include box;
</style>
